.spinButton {
	background: #00b3ff;
	min-width: 100px;
	max-width: fit-content;
	color: white;
	font-weight: 700;
	font-size: 20px;
	padding: 10px;
	border-radius: 6px;
	transition: all 200ms ease-in-out;
	border: 2px rgba(255, 255, 255, 0) solid;
	filter: drop-shadow(0px 5px 4px #0000004f);
}
.spinButton:hover {
	filter: drop-shadow(0px 5px 4px #4444dd);
	border: 2px white solid;
}
.spinButton:disabled {
  background-color: #bdc3c7;
  color: #7f8c8d;
  cursor: not-allowed;
}
.box {
	padding: 5%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	height: 100%;
}

.winner {
	color: white;
    font-weight: 700;
    font-size: 40px;
	position: absolute;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.733);
	border-radius: 5px;
	max-width: 70%;
	height: 30vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px 20px;
}
.winner-box{
	top: 0;
	right: 0;
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
