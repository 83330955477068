.add-debt-modal{
    position:absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.submit-btn{
    padding: 10px 10px;
    width: fit-content;
    align-self: flex-end;
    font-weight: 600;
    background-color: #6853de;
    color: white;
    border: none;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
}
.submit-btn:hover{

    background-color: #4f3fad;
    border: white;
}
.modal-box{
    width: 500px;
    height: 500px;
     z-index: 10;
      background-color: whitesmoke;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    
}
.modal-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}
.modal-header{
height: 6%;
width: 100%;
display: flex;
flex-direction: column;
}
.close-btn{
    align-self: flex-end;
    height: 10%;
    width: auto;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.modal-footer{
height: 10%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
}

.modal-row{
    display: flex;
    flex-direction: column;
    min-height: 20%;
}
label{
padding-bottom: 10px;
}
select{
    min-height: 20px;
}
.custom-select {
  min-width: 350px;
  position: relative;
}
.customInput{
    min-width: 350px;
  position: relative;
}

.custom-select::before,
.custom-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.custom-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.custom-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}