:root{
  height: 100vh;
}
select {
  appearance: none;
  /*  safari  */
  -webkit-appearance: none;
  font-size: 1.15rem;
  padding: 0.675em 6em 0.675em 1em;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;
}
input{  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  font-size: 1.15rem;
    padding: 0.675em 0em ;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 0.25rem;
  color: #000;
  cursor: pointer;}

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

li {
    margin: 0;
    padding: 0;
	    list-style: none;
}
body {
	background-image: linear-gradient(
		to right top,
		#f90ef9,
		#8487ff,
		#00b3ff,
		#00cbff,
		#12d9eb
	);
	background-repeat: no-repeat;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	position: relative;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
