.add-restaurant-modal{
    position:absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.restaurant-name-input{
    height: 30px;
}
.input-label{
    margin-bottom: 10px;
}
.submit-btn{
    padding: 10px 10px;
    width: fit-content;
    align-self: flex-end;
    font-weight: 600;
    background-color: #6853de;
    color: white;
    border: none;
    border-radius: 5px;
    transition: all 200ms ease-in-out;
}
.submit-btn:hover{

    background-color: #4f3fad;
    border: white;
}
.modal-box{
    width: 500px;
    height: 500px;
     z-index: 10;
      background-color: whitesmoke;
      padding: 5px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    
}
.modal-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}
.modal-header{
height: 6%;
width: 100%;
display: flex;
flex-direction: column;
}
.close-btn{
    align-self: flex-end;
    height: 100%;
    width: auto;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 20px;
    font-weight: 800;
    color: black;
}
.modal-footer{
height: 10%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
}


