.table-component{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    flex-direction: column;
}
.custom-table, th , td{
    border: solid 2px;
    border-collapse: collapse;
}
.custom-table td{
    width: 130px;
    padding: 10px;
}

@media only screen and (max-width: 600px) {
.custom-table, th , td{
   font-size: 14px;
}
.custom-table td{
    width: 20px;
    padding: 5px;
}

}