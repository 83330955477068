
.navbar {
    max-width: 100%;
    padding: 5px;
    height: fit-content;
    background-color: white;
    position: sticky;
    top: 0;
    padding: 1rem 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    gap: 20px;
    z-index: 10;
    flex-wrap: wrap;

}

.nav-item{
    flex: 1;
    justify-content: center;
    text-align: center;
}

.button-holder {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.title-nav {
    color: #6853de;
    font-weight: 600;
    font-size: 16px;
}

.nav-item {
    width: fit-content;
}

.Logo {
    height: 5vh;
}

.btn {
    min-width: 100px;
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 3px;
    border: none;
    color: white;
    font-weight: 700;
    transition: all 200ms ease-in-out;

}

.login-btn {
    background-color: #6853de;
}

.logout-btn {
    background-color: #de5353;
}

.restaurant-btn {
    background-color: #6853de;
}

.restaurant-btn:hover {

    background-color: #4f3fad;
    border: white;
}

.logout-btn:hover {

    background-color: #b94242;
    border: white;
}

.login-btn:hover {

    background-color: #4f3fad;
    border: white;
}

.profile-button{
    height: 30px;
}
.hover-menu {
    display: none;
}

.profile-wrapper{
    position:relative;
}

.profile-button:hover + .hover-menu {
    display: block;
}


@media only screen and (max-width: 600px) {
    .navbar {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .title-nav {
        font-size: 14px;
    }

    .Logo {
        height: 4vh;
        transform: translateX(0rem);

    }

    .btn {
        font-size: 12px;
        min-width: 100px;
        padding: 5px 15px;
        border-radius: 3px;
        border: none;
        color: white;
        font-weight: 700;
        transition: all 200ms ease-in-out;
    }
}